import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroHome from '../HeroHome';

const Home = () => {
  return (
    <div>
      <HeroHome />
      {/* <Cards /> */}
    </div>
  );
};

export default Home;
