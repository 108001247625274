import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import './App.css';
import NavBar from './components/NavBar';
import Bio from './components/pages/Bio';
import Home from './components/pages/Home';
import Music from './components/pages/Music';
import Shows from './components/pages/Shows';
import PastShows from './components/pages/PastShows';
import Footer from './components/Cards';

function App() {
  return (
    <div>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/bio" exact element={<Bio />} />
          <Route path="/music" exact element={<Music />} />
          <Route path="/shows" exact element={<Shows />} />
          <Route path="/pastShows" exact element={<PastShows />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
