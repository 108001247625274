import React from 'react';
import HeroBio from '../HeroBio';
// import Cards from '../Cards';
import '../../App.css';

function Bio() {
  return (
    <div>
      <HeroBio />
      {/* <Cards /> */}
    </div>
  );
}

export default Bio;
