import React from 'react';
import HeroMusic from '../HeroMusic';
import Cards from '../Cards';
import '../../App.css';

function Music() {
  return (
    <div>
      <HeroMusic />
      {/* <Cards /> */}
    </div>
  );
}

export default Music;
